<template>
  <div class="auth-wrapper auth-v1 align-start py-0">
    <div class="auth-inner">
      <v-card-text>
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-space-between align-center"
          >
            <div class="d-flex justify-space-between align-center">
              <v-img
                :src="appLogoTitle"
                max-width="120px"
                alt="logo"
                contain
                class="me-3 cursor-pointer"
                @click="$router.push('/')"
              ></v-img>
            </div>

            <div>
              <v-btn
                class="ma-0 py-2 px-1 no-bg-hover font-weight-regular"
                style="
                  text-transform: inherit;
                  min-width: 60px;
                "
                text
                color="secondary"
                @click="downloadApp"
              >
                {{ t('videos.download_app') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="px-0 py-1 ma-0 mt-12">
          <v-col
            cols="12"
            class="mt-12 mb-5 py-1 px-2 d-flex justify-center align-center"
          >
            <v-avatar
              color="#090909 !important"
              class="v-avatar-light-bg primary--text"
              size="150"
            >
              <v-icon
                class="pa-8"
                size="180"
                rounded="lg"
                color="primary"
              >
                {{ isInactive ? icons.mdiDeleteForever : icons.mdiCloseCircleOutline }}
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="mb-4 px-4 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <h3
              class="white--text"
            >
              <span v-if="isInactive">{{ t('video_cancelled.not_available') }}</span>
              <span v-else>{{ t('video_cancelled.not_valid') }}</span>
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <span class="text-center">
              {{ t('video_cancelled.click_search_videos') }}
            </span>
          </v-col>
        </v-row>

        <v-row class="my-5 px-4 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="text-capitalize"
              style="height: 42px !important"
              color="secondary"
              dark
              rounded
              block
              @click="onSearchVideos"
            >
              {{ t('video_cancelled.search_videos') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onMounted } from '@vue/composition-api'
import { useRouter, openTab } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'
import { mdiDeleteForever, mdiCloseCircleOutline } from '@mdi/js'

export default {
  setup() {
    const { router, route } = useRouter()
    const { t } = useUtils()

    const isInactive = ref(false)
    const isInvalid = ref(false)

    const downloadApp = () => {
      openTab('https://app.fanaty.com')
    }

    const onSearchVideos = () => {
      router.push({ name: 'views-facility-list' })
    }

    onMounted(() => {
      const { inactive, invalid } = route.value.params
      isInactive.value = inactive
      isInvalid.value = invalid
    })

    return {
      // data
      isInactive,
      isInvalid,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      downloadApp,
      onSearchVideos,
      t,

      icons: {
        mdiDeleteForever,
        mdiCloseCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
